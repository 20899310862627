<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <ayl-table :table="table">
          <div slot="ctrl-button">
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">+ 新增</el-button>
            <!-- <el-button plain @click="exportClick()" style="float:right">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const vm = this;
    return {
      nav: [{ name: "车辆作业路线" }],
      table: {
        api: vm.$api_hw.vehicleOperation_getRouteList,
        query: {
          simStatus: null,
          serviceProvider: null,
          queryContent: null
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "bidId",
            placeholder: "请选择",
            option: [],
            width: "180px"
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "线路名称"
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "线路名称",
            key: "routeName",
            showTooltip: true,
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.urbanVillagesDetails.bind(this, ctx.row)
                    }
                  },
                  ctx.row.routeName || "- -"
                )
              ]);
            }
          },
          {
            title: "所属标段",
            key: "bidName"
            // width: "100px"
          },
          {
            title: "保洁等级",
            key: "cleanLevel",
            width: "100px",
            render: (h, ctx) => {
              if (ctx.row.cleanLevel) {
                let row = ctx.row.cleanLevel;
                const text = {
                  "0": "特级",
                  "1": "一级",
                  "2": "二级",
                  "3": "三级"
                }[row];
                return h("span", text);
              } else {
                return h("span", "- -");
              }
            }
          },
          {
            title: "每趟次数(机扫)",
            key: "sweeperTripTime",
            width: "120px"
          },
          {
            title: "要求趟数(机扫)",
            key: "sweeperTripNum",
            width: "120px"
          },
          {
            title: "每趟次数(洒水)",
            key: "sprinklerTripTime",
            width: "120px"
          },
          {
            title: "要求趟数(洒水)",
            key: "sprinklerTripNum",
            width: "120px"
          },
          {
            title: "扫路车",
            key: "dutySweeperCar",
            width: "100px"
          },
          {
            title: "清洗车",
            key: "dutySprinklerCar",
            width: "100px"
          },
          {
            title: "变更类型",
            key: "changeType",
            width: "100px",
            align: "center",
            render: (h, ctx) => {
              let row = ctx.row.changeType;
              // const color = { "0": "success", "1": "" }[row];
              const text = { "0": "新增", "1": "修改" }[row];
              return h(
                "span",
                // { props: { type: color, effect: "plain" } },
                text
              );
            }
          },
          {
            title: "状态",
            key: "checkStatus",
            width: "100px",
            align: "center",
            render: (h, ctx) => {
              let row = ctx.row.checkStatus;
              const text = {
                "0": "未提交",
                "1": "已提交",
                "2": "审核通过",
                "3": "审核未通过"
              }[row];
              return h("span", text);
            }
          },
          {
            title: "审核意见",
            key: "checkResult",
            width: "100px"
          },
          {
            title: "操作",
            width: "150px",
            align: "center",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.editVehicleOperation.bind(this, ctx.row)
                    }
                  },
                  "编辑"
                )
              ]);
            }
          }
        ]
      }
    };
  },
  methods: {
    /**
     * 编辑路线
     */
    editVehicleOperation(v) {
      console.log(v.routeId);
      this.$router.push({
        path: "/district-management/vehicle-operation/edit-vehicle-operation",
        query: { routeId: v.routeId }
      });
    },

    //导出
    exportClick() {},

    //路线详情
    urbanVillagesDetails(v) {
      this.$router.push({
        path:
          "/district-management/vehicle-operation/details-vehicle-operation",
        query: { routeId: v.routeId }
      });
    },

    //新增路线
    onAdd() {
      this.$router.push({
        path: "/district-management/vehicle-operation/add-vehicle-operation"
      });
    }
  },
  async activated() {
    let _this = this;
    await _this.$search(_this.table);
    _this.table.searchData[0].option = await _this.$api_hw.common_getBidNameAndIdList(
      {}
    );

    BUS.$on(BUSEVENT.REFRESH_GREEN_GARBAGE_HOUSE, () => {
      _this.onRefresh();
    });
  }
};
</script>

<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
  
  .drawer-main
    margin: 5px 15px
    .drawer-btn
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(237,237,237,1)
      border-radius: 2px
      outline: none
      border: 0
      &:hover
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
      &:before
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
    .drawer-btn1
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      outline: none
      border: 1px solid transparent
      color: #fff

  .drawer-contract
    margin: 30px 15px 10px
    .drawer-contract-item
      width: 100%
      .drawer-contract-span
        color: #2E3033
    .drawer-contract-item1
      width: 48%
      .drawer-contract-span
        color: #2E3033
  .drawer-hr
    width: 100%
    height: 1px
    background: rgba(232,232,232,1)
  .drawer-map
    margin: 20px 15px 10px
    .drawer-map-title
      color: rgba(46,48,51,1)
  /deep/.el-drawer__header 
      margin-bottom: 15px
      padding-left: 15px
      color: #222
      font-size: 16px
  /deep/.el-button--success 
      color: #FFFFFF
      background-color: rgba(26,188,156,1)
      border-color: rgba(26,188,156,1)

</style>
